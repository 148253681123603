body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.control-panel {
  font-size: 14px;
  line-height: 18px;
  /*width: 284px;*/
  background: #fff;
  box-shadow: 0 0 4px rgb(0 0 0 / 15%);
  margin: 5px;
  padding: 12px 24px;
  position: absolute;
  top: 0px;
  right: 0;
  outline: none;
  cursor: auto;
}

.my-custom-scrollbar {
position: relative;
height: 200px;
overflow: auto;
}
.table-wrapper-scroll-y {
display: block;
}